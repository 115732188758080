<template>
  <div class="text-center">
    <v-bottom-sheet inset persistent :value="Boolean(chunkId)">
      <v-sheet class="pa-5" height="90vh" rounded="lg">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-btn color="black" icon @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <p class="text-h6">Original Source</p>
          </div>

          <v-chip
            v-if="sourceInfo.plagiarised_confidence"
            color="primary"
            label
            outlined
          >
            Plagiarism Confidence:
            {{ parseFloat(sourceInfo.plagiarised_confidence).toFixed(1) }}%
          </v-chip>
        </div>

        <div v-if="sourceInfo.user" class="d-flex align-center mt-4">
          <base-avatar
            :name="sourceInfo.user.full_name"
            :photo="sourceInfo.user.photo"
            size="45"
          />
          <div class="ml-2">
            <p class="text-h6">
              {{ sourceInfo.user.full_name }}
            </p>
            <p class="lightCarbon--text font-weight-regular">
              {{ sourceInfo.user.email }}
            </p>
          </div>
        </div>

        <div class="submission-container" style="overflow: auto; height: 80%;">
          <p class="text-h6 text--carbon">
            Submission
          </p>
          <div v-if="sourceInfo.plagiarised_from_updated_html">
            <DisabledEditor :text="sourceInfo.plagiarised_from_updated_html" />
          </div>
          <v-progress-linear v-else class="mt-2" indeterminate />
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import urls from "@/utils/urls"
import DisabledEditor from "@/components/dashboard/assignments/DisabledEditor"

export default {
  name: "OriginalSourceSheet",

  components: {
    DisabledEditor,
  },

  props: {
    chunkId: {
      default: null,
      type: String,
      required: false,
    },
    submissionId: {
      default: null,
      type: String,
      required: false,
    },
  },

  data: () => ({
    sourceInfo: {},
  }),

  watch: {
    chunkId(newValue, _) {
      if (newValue) this.getPlagiarismReport(newValue)
    },
  },

  mounted() {
    // remove scroll bar from body, html
    document.body.style.overflow = "hidden"
    document.documentElement.style.overflow = "hidden"
  },

  beforeDestroy() {
    // add scroll bar to body
    document.body.style.overflow = "auto"
    document.documentElement.style.overflow = "auto"
  },

  methods: {
    async getPlagiarismReport(chunkId) {
      try {
        const response = await this.$http.get(
          urls.submissions.getPlagiarismSource(this.submissionId, this.chunkId)
        )
        this.sourceInfo = response.data.data

        setTimeout(() => {
          const container = document.querySelector(".submission-container")
          const element = container.querySelector(
            ".suraasa-plagiarism-original-source"
          )
          element.scrollIntoView({ behavior: "smooth", block: "center" })
        }, 500)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.submission-container {
  margin-top: 24px;
  padding: 16px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
}
</style>
