<template>
  <div :class="showPlagiarismHighlights && 'show-plagiarism-highlights'">
    <div
      class="assignment-container-scrollbars container-fixed-height"
      style="overflow: auto;"
    >
      <div v-if="plagiarismScore">
        <v-alert class="plagiarism-alert" color="#F7E4A6" dense>
          <span>
            This submission seems to be plagiarised. Please evaluate the
            assignment accordingly.
          </span>
          <span class="font-weight-bold">
            Plagiarism Score:
            {{ plagiarismScore }}%
          </span>
        </v-alert>
      </div>
      <div v-if="aiLevel">
        <v-alert :class="`ai-alert ai-alert-${aiLevel}`" dense>
          <span>
            Our system has detected a
            <span class="font-weight-bold">{{ aiLevel }}</span> probability that
            this submission was generated using AI.
          </span>
          <span class="text-subtitle-2">
            You may choose to take action based on this information
          </span>
        </v-alert>
      </div>

      <div class="pa-5 assignment-detail">
        <p class="text-h6 text--carbon heading pa-3">
          {{ assignment.title | formatAssignmentTitle(assignment.short_title) }}
        </p>

        <DisabledEditor class="py-3" :text="assignment.instructions" />

        <div v-if="assignment.attachments" class="py-4">
          <span
            v-for="attachment in assignment.attachments"
            :key="attachment.id"
          >
            <v-chip
              color="aluminium"
              :href="attachment.attachment"
              label
              target="_blank"
              text-color="primary"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              <span class="text-truncate">
                {{ getFileName(attachment.name) }}
              </span>
            </v-chip>
          </span>
        </div>

        <v-divider />

        <div
          class="d-flex mt-5 mb-2 heading text--carbon pa-3 justify-space-between"
        >
          <p class="text-h6">
            Submission
          </p>
          <p v-if="submission.submitted_at" class="text-h6">
            {{ moment(submission.submitted_at).format("ll") }}
          </p>
        </div>

        <div v-if="plagiarismScore" class="d-flex justify-end pb-1">
          <v-switch
            v-model="showPlagiarismHighlights"
            class="ma-0 plagiarism-highlight-switch"
            dense
            hide-details
            inset
            label="Highlight Plagiarism"
          />
        </div>

        <DisabledEditor :loading="loading" :text="submissionText" />

        <div v-if="submission.references">
          <p class="text-h6 my-5 text--carbon heading pa-3">
            References
          </p>

          <DisabledEditor :text="submission.references" />
        </div>

        <div v-if="submission.citations">
          <p class="text-h6 my-5 text--carbon heading pa-3">
            Citations
          </p>

          <DisabledEditor :text="submission.citations" />
        </div>

        <div
          v-if="submission.attachments && submission.attachments.length"
          class="py-2"
        >
          <p class="text-h6 my-5 text--carbon heading pa-3">
            Attachments
          </p>
          <v-chip
            v-for="attachment in submission.attachments"
            :key="attachment.id"
            class="mr-2 mb-2"
            color="aluminium"
            label
            text-color="primary"
            @click="downloadAttachment(attachment.id)"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            <span class="text-truncate">
              {{
                isDownloading ? "Downloading..." : getFileName(attachment.name)
              }}
            </span>
          </v-chip>
        </div>

        <div
          v-if="
            plagiarismReport.plagiarised_sources_users &&
              plagiarismReport.plagiarised_sources_users.length > 0
          "
        >
          <p class="text-h6 my-5 text--carbon heading pa-3">
            Plagiarism Sources
          </p>

          <div
            v-for="(item, i) in plagiarismReport.plagiarised_sources_users"
            :key="item.source_submission_uuid"
            class="d-flex align-center"
          >
            <div class="d-flex align-center" style="gap: 4px;">
              <span class="plagiarism-source-number">{{ i + 1 }}</span>
              {{ item.user.full_name }}
            </div>
          </div>
        </div>
      </div>

      <original-source-sheet
        :chunk-id="selectedPlagiarismChunkId"
        :submission-id="submission.uuid"
        @close="selectedPlagiarismChunkId = null"
      />
    </div>
  </div>
</template>

<script>
import DisabledEditor from "@/components/dashboard/assignments/DisabledEditor"
import OriginalSourceSheet from "@/components/dashboard/assignments/OriginalSourceSheet"

import urls from "@/utils/urls"
import filters from "@/utils/mixins/filters"

import split from "lodash/split"

export default {
  name: "SubmissionDetails",

  components: {
    DisabledEditor,
    OriginalSourceSheet,
  },

  mixins: [filters],

  props: {
    assignment: {
      type: Object,
      required: true,
    },
    submission: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showPlagiarismHighlights: true,
    selectedPlagiarismChunkId: null,
    isDownloading: false,
    plagiarismReport: {},
  }),

  computed: {
    submissionText() {
      if (this.plagiarismReport.updated_html) {
        return this.plagiarismReport.updated_html
      }
      return this.submission.text === "null" || this.submission.text === ""
        ? ""
        : this.submission.text
    },

    plagiarismScore() {
      if (
        this.plagiarismReport.overall_plagiarism_score &&
        this.plagiarismReport.plagiarism_detected
      )
        return Math.round(
          parseFloat(this.plagiarismReport.overall_plagiarism_score)
        )

      return 0
    },
    aiLevel() {
      if (!this.plagiarismReport.probable_ai_written) return null

      const confidence = Math.round(
        parseFloat(this.plagiarismReport.probable_ai_written)
      )

      if (!confidence) return null

      if (confidence <= 50) return "low"
      if (confidence <= 80) return "medium"
      if (confidence <= 100) return "high"

      return null
    },
  },

  watch: {
    submission() {
      if (this.submission.uuid) this.getPlagiarismReport()
    },
  },

  methods: {
    addListeners() {
      const elements = document.querySelectorAll(".suraasa-plagiarism-detected")
      console.log(`Adding listeners to ${elements.length} chunks`)
      elements.forEach(el =>
        el.addEventListener("click", this.showPlagiarismSource)
      )

      // Setup source grouping
      elements.forEach(el => {
        const chunkId = el.getAttribute("data-content-id")
        const sourceId = el.getAttribute("source-uuid")

        if (!sourceId) {
          console.warn(`Source ID not found for chunk: ${chunkId}`)
          return
        }

        const groupId =
          this.plagiarismReport.plagiarised_sources_users.findIndex(
            source => source.source_submission_uuid === sourceId
          ) + 1

        if (groupId <= 0) {
          throw new Error(`Group ID not found for ${sourceId}`)
        }
        el.setAttribute("data-before", groupId)
      })
    },

    showPlagiarismSource(e) {
      const element = e.target
      const id = element.getAttribute("data-content-id")
      this.selectedPlagiarismChunkId = id
    },

    async downloadAttachment(id) {
      this.isDownloading = true

      try {
        const response = await this.$http.get(
          urls.submissions.downloadAttachment(id)
        )
        window.open(response.data.data.url)
      } catch (e) {
        console.error(e)
      }

      this.isDownloading = false
    },

    async getPlagiarismReport() {
      this.plagiarismReport = {}
      console.log("> Getting plagiarism report...")
      try {
        const response = await this.$http.get(
          urls.submissions.getPlagiarisedSubmission(this.submission.uuid)
        )
        this.plagiarismReport = response.data.data

        setTimeout(() => {
          this.addListeners()
        }, 1000)
      } catch (e) {
        console.error(e)
      }
    },

    getFileName(path) {
      /**
       * Input: submissions/my-file.txt
       * Output: my-file.txt
       */
      return split(path, "/").pop()
    },
  },
}
</script>

<style lang="scss">
.assignment-detail {
  background: white;
  border-radius: 5px;
}

.heading {
  background: #e3ebef;
  border-radius: 5px;
}

.plagiarism-alert .v-alert__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #544103;
}

.ai-alert .v-alert__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ai-alert-high,
.ai-alert-high .v-alert__content {
  color: #4a1616 !important;
  background: #f7e1e1 !important;
}

.ai-alert-medium,
.ai-alert-medium .v-alert__content {
  color: #544103 !important;
  background: #fcf3d8 !important;
}

.ai-alert-low,
.ai-alert-low .v-alert__content {
  color: #1e3a8a !important;
  background: #dbeafe !important;
}

.plagiarism-highlight-switch {
  .v-label {
    font-size: 14px;
    color: black;
  }

  .v-input__slot {
    flex-direction: row-reverse;
    gap: 8px;
  }
}

.plagiarism-source-number {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  border-radius: 50%;
  color: #4a1616;
  background-color: #e18a8a;
}
</style>
