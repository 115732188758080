var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.rubrics),function(item){return _c('div',{key:item.id},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" ("+_vm._s(item.task)+") "+_vm._s(_vm.getCapitalizedStr(item.criteria))+" ")])]),_c('v-row',{staticClass:"mb-4"},_vm._l((item.cells),function(cell){return _c('v-col',{key:cell.uuid,attrs:{"cols":"12","lg":"4","md":"6"}},[_c('div',{class:[
            'review-cell px-3 pt-3',
            {
              'ai-suggested-card':
                !_vm.disabled && _vm.aiSuggestions.includes(cell.uuid),
              'review-cell-selected': !_vm.disabled && cell.currentlySelected,
              'review-cell-inactive': !_vm.disabled && !cell.currentlySelected,
              'review-cell-professor':
                _vm.showProfessorSelected && cell.professorSelected && _vm.disabled,
              'review-cell-iqa': _vm.showIqaSelected && cell.IQASelected,
              'review-cell-marked-by-iqa-and-professor':
                _vm.showProfessorSelected &&
                cell.professorSelected &&
                _vm.showIqaSelected &&
                cell.IQASelected,
              cursor: !_vm.disabled,
            },
          ],on:{"click":function($event){!_vm.disabled
              ? _vm.$emit('mark', { scale: cell.scale, criteria: item.id })
              : () => {}}}},[_c('p',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(cell.scale)+" "+_vm._s(cell.scale === 1 ? "Point" : "Points")+" ")]),_c('p',{staticClass:"mt-1 mb-3 text-subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(cell.text)+" ")]),_c('div',{staticClass:"ai-suggested"},[_c('p',{staticClass:"flex justify-center"},[_c('v-img',{attrs:{"max-height":"25","max-width":"25","src":require('@/assets/icons/union-white.svg')}}),_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" AI Suggested ")])],1)]),_c('div',{staticClass:"ai-suggested-selected"},[_c('p',[_c('v-img',{staticClass:"mx-auto",attrs:{"max-height":"25","max-width":"25","src":require('@/assets/icons/union-sky.svg')}})],1)])])])}),1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }